'use client';

import { useSellerAdminSettings } from './use-settings';

export const useFeatureFlip = () => {
  const { SmartOrderSettings } = useSellerAdminSettings();
  let orderAcknowledgmentActivated = false;
  const threshold = SmartOrderSettings?.orderAcknowledgmentThresholdMinutes;

  if (threshold && Number.isInteger(threshold)) {
    orderAcknowledgmentActivated = true;
  }

  return { orderAcknowledgmentActivated } as const;
};
